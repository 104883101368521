export const developer_types = [
  {
    type: 'ready-made lover',
    text: `By default, Atomizify offers thousands of predefined classes.
Just call the function, and a whole galaxy of CSS classes appears.`,
    code: '*$atomizify*()',
  },
  {
    type: 'less-is-more expert',
    text:
      'Atomizify lets you select / unselect the classes of your choice among the predefined sets & generate only the substancial ones.',
    code: `*/// you can pass the 'filters' object, which accepts 3 sub-objects: 'sets', 'subsets', 'properties'*
*/// each sub-object accepts 2 key-values: 'names' and 'to_remove'*

*$atomizify*({ 
  *%filters*: { 
    *&sets*: {
      *#names*: ['layout'], */// 'names' is an array containing the names of the targetted items (to keep, or to remove if the prop 'to_remove' is passed to 'true')*
    }, 
    *&subsets*: {
      *#names*: ['dimensions']
    },
    *&properties*: {
      *#names*: ['min-height', 'min-width'],
      *#to_remove*: true */// set to 'true' if you want to remove the targetted items listed in 'names'*
    }
  }
})`,
  },
  {
    type: 'custom-all freak',
    text: `Atomizify gives you complete freedom to create and add your own custom classes. You can also personalize the typecase system for your classes' names.`,
    code: `*$atomizify*({ 
  *%typecase*: 'dash', */// choose among 'snake', 'dash' or 'camel' case ; default is 'snake'*
  *%custom_classes*: { 
    *&myclass1*: 'background: black; color: turquoise',
    *&myclass2*: 'border-color: pink',
    *&myclass3*: 'font-size: 200px',
  }
})`,
  },
]
