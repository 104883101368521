import { atomizify, flagify } from 'atomizify'

const custom_classes = {
  fs55: `font-size: 55px`,
  sans_serif: `font-family: 'IBM Plex Sans'`,
  monospace: `font-family: 'IBM Plex Mono'`,
}

const filters = {
  sets: { names: ['layout', 'theming'] },
  subsets: { names: ['positioning'], to_remove: true },
  properties: { names: ['min-width'], to_remove: true },
}

atomizify({
  // filters,
  custom_classes,
  // typecase: 'snake',
})

export const { Div, Span, Component } = flagify()
